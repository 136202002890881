<template>
  <div>
    <div class="price">
      <div class="price-title">
        产品价格
      </div>
      <div class="price-title-content">
        我们为不同规模的企业提供了不同的价格方案，小投入大产出。
      </div>
      <div class="price-list">
        <div class="price-item">
          <div class="item">
            <div class="item-top">
              <div class="top-title">
                专业版
              </div>
              <div class="top-content">
                本组织范围内协同服务
              </div>
              <div class="top-price">
                ￥<span class="big">365</span>元/人/年
              </div>
            </div>
            <div class="item-content">
              <div class="content-item">
                <div class="item-product">
                  小程序
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  移动APP
                </div>
                <div class="item-whether">
                  不支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  开发接口API
                </div>
                <div class="item-whether">
                  不开放
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  微信集成
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  网站集成
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  员工人数
                </div>
                <div class="item-whether">
                  购买账号数
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  客户数量
                </div>
                <div class="item-whether">
                  不限
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  专属客户经理
                </div>
                <div class="item-whether">
                  有
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  人工客服
                </div>
                <div class="item-whether">
                  5*8小时
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  运维支持
                </div>
                <div class="item-whether">
                  7*24小时
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="price-item1">
          <div class="item">
            <div class="item-top">
              <div class="top-title">
                企业版
              </div>
              <div class="top-content">
                支持跨组织协同服务
              </div>
              <div class="top-price">
                ￥<span class="big">599</span>元/人/年
              </div>
            </div>
            <div class="item-content">
              <div class="content-item">
                <div class="item-product">
                  小程序
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  移动APP
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  开发接口API
                </div>
                <div class="item-whether">
                  不开放
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  微信渠道集成
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  网站渠道集成
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  员工人数
                </div>
                <div class="item-whether">
                  购买账号数
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  客户数量
                </div>
                <div class="item-whether">
                  不限
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  专属客户经理
                </div>
                <div class="item-whether">
                  有
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  人工客服
                </div>
                <div class="item-whether">
                  5*8小时
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  运维支持
                </div>
                <div class="item-whether">
                  7*24小时
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="price-item2">
          <div class="item">
            <div class="item-top">
              <div class="top-title">
                私有部署
              </div>
              <div class="top-content">
                专属的企业售后管理平台
              </div>
              <div class="top-price">
                联系客服
              </div>
            </div>
            <div class="item-content">
              <div class="content-item">
                <div class="item-product">
                  专属APP、小程序
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  移动应用APP
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  开发接口API
                </div>
                <div class="item-whether">
                  开放
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  微信渠道集成
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  网站渠道集成
                </div>
                <div class="item-whether">
                  支持
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  员工人数
                </div>
                <div class="item-whether">
                  购买账号数
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  客户数量
                </div>
                <div class="item-whether">
                  不限
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  专属客户经理
                </div>
                <div class="item-whether">
                  有
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  人工客服
                </div>
                <div class="item-whether">
                  5*8小时
                </div>
              </div>
              <div class="content-item">
                <div class="item-product">
                  运维支持
                </div>
                <div class="item-whether">
                  7*24小时
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productPrice",
  mounted() {
    console.log("download", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      this.$router.replace('/mobilePrice')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/productPrice')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 判断是什么设备
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  },
}
</script>

<style lang="scss" scoped>
.price {
  height: 1100px;
  background: url("../../../public/prod_price_bg.png");

  .price-title {
    line-height: 40px;
    text-align: center;
    font-size: 32px;
    color: #333333;
    padding-top: 185px;
    font-weight: bold;
  }

  .price-title-content {
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    color: #333333;
    margin-top: 10px;
    font-weight: bold;
  }
  .price-list{
    display: flex;
    justify-content: center;
    .price-item {
      margin-right: 20px;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      float: left;
      margin-top: 70px;
      overflow: hidden;
      .item-top {
        padding-top: 50px;
        background: #6aa5d9;
        height: 201px;
        color: #ffffff;
        .top-title {
          font-size: 26px;
          text-align: center;
        }
        .top-content {
          font-size: 17px;
          margin: 15px 0;
        }
        .top-price{
          .big{
            font-size: 45px;
          }
        }
      }
      .item-content{
        padding: 30px 40px;
        .content-item{
          display: flex;
          justify-content: space-between;
          .item-product{
            font-weight: bold;
            line-height: 40px;
          }
          .item-whether{
            font-weight: bold;
            line-height: 40px;
            color: #6aa5d9;
          }
        }
      }
    }
    .price-item1 {
      margin-right: 20px;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      float: left;
      margin-top: 70px;
      overflow: hidden;
      .item-top {
        padding-top: 50px;
        background: #ef8b44;
        height: 201px;
        color: #ffffff;
        .top-title {
          font-size: 26px;
          text-align: center;
        }
        .top-content {
          font-size: 17px;
          margin: 15px 0;
        }
        .top-price{
          .big{
            font-size: 45px;
          }
        }
      }
      .item-content{
        padding: 30px 40px;
        .content-item{
          display: flex;
          justify-content: space-between;
          .item-product{
            font-weight: bold;
            line-height: 40px;
          }
          .item-whether{
            font-weight: bold;
            line-height: 40px;
            color: #ef8b44;
          }
        }
      }
    }
    .price-item2 {
      margin-right: 20px;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      float: left;
      margin-top: 70px;
      overflow: hidden;
      .item-top {
        padding-top: 50px;
        background: #68d19d;
        height: 201px;
        color: #ffffff;
        .top-title {
          font-size: 26px;
          text-align: center;
        }
        .top-content {
          font-size: 17px;
          margin: 15px 0;
        }
        .top-price{
          .big{
            font-size: 45px;
          }
        }
      }
      .item-content{
        padding: 30px 40px;
        .content-item{
          display: flex;
          justify-content: space-between;
          .item-product{
            font-weight: bold;
            line-height: 40px;
          }
          .item-whether{
            font-weight: bold;
            line-height: 40px;
            color: #68d19d;
          }
        }
      }
    }
  }

}
</style>
